.footer-container {
    background: black;
    color: white;
    width: 100%;
  
    .footer-content {
      padding: 50px 30px 50px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  
      @media screen and (min-width: 570px) {
        flex-direction: column;
      }
  
      @media screen and (min-width: 1064px) {
        flex-direction: row;
        justify-content: space-between;
      }
  
      @media screen and (min-width: 1246px) {
        padding: 50px 150px 50px 100px;
      }
      .footer-logo {
        display: flex;
        justify-content: center;
        img {
          cursor: pointer;
          justify-content: flex-start;
          width: 221px;
          height: 66px;
          display: none;
  
          @media screen and (min-width: 570px) {
            visibility: visible;
            display: flex;
          }
        }
  
        @media screen and (min-width: 1064px) {
          flex-direction: row;
          justify-content: space-between;
        }
      }
      .footer-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
  
        @media screen and (min-width: 570px) {
          flex-direction: row;
        }
  
        .footer-info-box {
          display: flex;
          flex-direction: column;
          padding-right: 50px;
        //   padding-bottom: 30px;
  
          @media screen and (min-width: 570px) {
            visibility: visible;
            display: flex;
            padding-bottom: unset;
          }
  
          .footer-info-box-header {
            font-size: 20px;
            color: #FFFFFF;
            letter-spacing: 0;
            padding-bottom: 10px;
          }
          .footer-info-line {
            cursor: pointer;
            font-size: 15px;
            color: #FFFFFF;
            margin-bottom: 5px;
          }
          .social-media-links {
            img {
              width: 46px;
              height: 46px;
              padding-right: 10px;
              cursor: pointer;
            }
          }
        .nav-link {
          text-decoration: none;
          padding-bottom: 5px;
          color: #FFFFFF;
        }
        }
      }
    }
  }