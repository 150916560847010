.ant-input-affix-wrapper, .ant-input, .ant-alert {
    border-radius: 5px;
}

button {
    border-radius: 5px !important;
}

.text-center {
    text-align: center;
}