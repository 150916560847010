.message__container {
    font-size: 18px;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    height: 70vh;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 40px;
}