.header__container {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #d5d3d3;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 15vh;
  margin-right: 20px;
  margin-left: 20px;
}

.header__logo {
    margin-left: 50px;
    width: 221px;
    height: 66px;
    cursor: pointer;
}
